import React from 'react';

// Externals

// Components
import Onboarding from 'components/legacy/pages/authentication/onboarding';
import OnboardingLayout from 'components/legacy/layouts/onboarding';
import Script from 'next/script';

// Utils

// I18N

// import plural rules for all locales

// Store

const HomePage = () => {
  return (
    <OnboardingLayout>
      <Script id="axept-io">
        {`
                if (!window.location.href.includes('emailReport')) {
                  window.axeptioSettings = { clientId: '63e17fa030323fa6ba60ec97', cookiesVersion: 'urbiquest-fr' };
                  (function (d, s) {
                    var t = d.getElementsByTagName(s)[0],
                      e = d.createElement(s);
                    e.async = true;
                    e.src = '//static.axept.io/sdk.js';
                    t.parentNode.insertBefore(e, t);
                  })(document, 'script');
                }
              `}
      </Script>
      <Onboarding />
    </OnboardingLayout>
  );
};

export const getServerSideProps = async (context) => {
  return {
    props: {}
  };
};

export default HomePage;
